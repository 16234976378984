export const DELIVERY_CHANNELS = {
  IN_APP: 'In-app',
  EMAIL: 'Email',
  SMS: 'SMS (Twilio)',
  WHATSAPP: 'WhatsApp (Twilio)',
  TELEGRAM: 'Telegram'
};

export const PROFILE_NOTIFICATION_ITEMS = {
  [DELIVERY_CHANNELS.IN_APP]: {
    icon: '$alert_plus',
    title: 'Notification settings',
    popupTitle: 'Notification settings'
  },
  [DELIVERY_CHANNELS.SMS]: {
    icon: '$message_alert',
    title: 'SMS (Twilio)',
    popupTitle: 'Sms notifications'
  },
  [DELIVERY_CHANNELS.WHATSAPP]: {
    icon: '$whatsapp',
    title: 'WhatsApp (Twilio)',
    popupTitle: 'WhatsApp notifications'
  },
  [DELIVERY_CHANNELS.EMAIL]: {
    icon: '$postcode',
    title: 'E-mail notifications',
    popupTitle: 'E-mail notifications'
  },
  [DELIVERY_CHANNELS.TELEGRAM]: {
    icon: '$send',
    title: 'Telegram notifications',
    popupTitle: 'Telegram notifications'
  }
};
